import {combineReducers} from 'redux';
import alert from './alert';
import auth from './auth';
import profiles from './profile';
import users from './users';

export default combineReducers({
  alert,
  auth,
  profiles,
  users,
});
