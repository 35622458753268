import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import { direct_login } from '../../actions/auth';
import config from '../../config';

const Auth = ({ match, direct_login, auth: { isAuthenticated, loading } }) => {
  direct_login(match.params.id);

  // redirect if logged in
  loading = true;
  setTimeout(() => {
    if (isAuthenticated) {
      window.location.href = config.PANEL_URL + '?tkn=' + localStorage.token;
    }
    loading = false;
    return <Spinner></Spinner>;
  }, 1000);
  return loading ? (
    <Spinner></Spinner>
  ) : (
    <div className='card'>
      <div className='card-body'>
        <h5 className='card-title'>Uh Oh</h5>
        <h6 className='card-subtitle mb-2 text-muted'>Direct Login Failed</h6>
        <p className='card-text'>
          You can login using your email and password in the login form.{' '}
          <Link to='/'>Dashboard</Link>
        </p>
      </div>
    </div>
  );
};

Auth.propTypes = {
  direct_login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { direct_login })(Auth);
