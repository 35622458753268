import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {logout} from '../../actions/auth';
import config from '../../config';
import Can from '../routing/Can';

const Navbar = ({auth: {isAuthenticated, loading, user}, logout}) => {
  const Links = isAuthenticated ? (
    <Fragment>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNav'
        aria-controls='navbarNav'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon' />
      </button>
      <div className='collapse navbar-collapse' id='navbarNav'>
        <ul className='navbar-nav'>
          <Can
            role={user.role}
            perform='profile:visit'
            yes={() => (
              <Fragment>
                <li className='nav-item'>
                  <Link className='nav-link' to='#!' onClick={logout}>
                    Logout
                  </Link>
                </li>
              </Fragment>
            )}
            no={() => ''}
          />
        </ul>
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNav'
        aria-controls='navbarNav'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon' />
      </button>
      <div className='collapse navbar-collapse' id='navbarNav'>
        <ul className='navbar-nav'></ul>
      </div>
    </Fragment>
  );

  return (
    <div className=''>
      <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
        <Link to='/' className='navbar-brand'>
          {config.TITLE}
        </Link>
        {!loading && <Fragment>{Links}</Fragment>}
      </nav>
    </div>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {logout})(Navbar);
