const dev = {
  URL: 'http://localhost:3002/',
  BACKEND_URL: 'http://localhost:4022/',
  TAP_URL: 'http://localhost:3001/peoplehr/',
  PANEL_URL: 'http://localhost:3000/',
  TITLE: 'Krafthaus Single Login - Dev',
};

const prod = {
  URL: 'https://auth.kraftha.us/',
  BACKEND_URL: 'http://localhost:4022/',
  TAP_URL: 'https://slack.kraftha.us/peoplehr/',
  PANEL_URL: 'https://panel.kraftha.us/',
  TITLE: 'Krafthaus Single Login ',
};

const config = process.env.REACT_APP_STAGE === 'dev' ? dev : prod;

export default config;
