import React from 'react';
import PropTypes from 'prop-types';
import {logout} from '../../actions/auth';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';

const Logout = ({logout, user}) => {
  if (user) {
    logout(user);
    let source = window.location.href;
    if (source.includes('?src=')) {
      source = source.split('?src=');
      source = source[1];
      // window.location.href = source;
    }
  }
  // return <Redirect to='/' />;
  return <Spinner></Spinner>;
};

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {logout})(Logout);
