import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../actions/alert';
import { generate, login } from '../actions/auth';
import PropTypes from 'prop-types';
import config from '../config';
import Spinner from '../components/layout/Spinner';
const crypto = require('crypto');

const Landing = ({
  setAlert,
  generate,
  login,
  auth: { loading, isAuthenticated },
}) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    submit: '',
  });
  // redirect if logged in
  setTimeout(() => {
    if (isAuthenticated) {
      let source = window.location.href;
      if (source.includes('?src=')) {
        source = source.split('?src=');
        source = source[1];
        if (source.includes('?tkn')) {
          source = source.split('?tkn=');
          source = source[0];
        }
        window.location.href = source + '?tkn=' + localStorage.token;
      } else {
        window.location.href = config.PANEL_URL + '?tkn=' + localStorage.token;
      }
    }
    loading = false;
  }, 1000);

  const { email, password, submit } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      email.includes('@krafthaus') ||
      email.includes('@paperlust') ||
      email.includes('@obdservices')
    ) {
      let slug = await crypto.createHash('md5').update(email).digest('hex');
      let passwordHash = await crypto
        .createHash('md5')
        .update(password)
        .digest('hex');
      if (submit === 'Register') {
        generate({
          slug,
          email,
          passwordHash,
        });
      } else {
        login({
          slug,
          email,
          passwordHash,
        });
      }
    } else {
      setAlert('Please use @krafthaus email.', 'error');
    }
  };

  return loading ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <div className='jumbotron'>
        <h1 className='display-4'>{config.TITLE}!</h1>
        <p className='lead'>
          <b>Login</b> to your {config.TITLE} to <b>track</b> your{' '}
          <i>work time</i> and <b>check</b> your <i>holiday</i> and <i>WFH</i>{' '}
          quota.
          <br />
          <b>Bookmark</b> your direct login link to get quicker access to login.{' '}
          <b>Find</b> the direct link in <i>Profile</i> menu.
          <br />
          <b>Provide</b> company with your latest <i>address</i>.
        </p>
        <hr className='my-4' />
        <form name='generate-login' onSubmit={(e) => onSubmit(e)}>
          <div className='form-group'>
            <label htmlFor='email'>Email address</label>
            <input
              required
              type='email'
              name='email'
              className='form-control'
              id='email'
              aria-describedby='emailHelp'
              placeholder='Enter email'
              value={email}
              onChange={(e) => onChange(e)}
            />
            <small id='emailHelp' className='form-text text-muted'>
              Use your @krafthaus / @paperlust / @obdservices email.
            </small>
          </div>
          <div className='form-group'>
            <label htmlFor='password'>Password</label>
            <input
              required
              type='password'
              name='password'
              className='form-control'
              id='password'
              placeholder='Enter password'
              value={password}
              onChange={(e) => onChange(e)}
            />
          </div>
          <input
            type='submit'
            name='submit'
            className='btn btn-block btn-success'
            value='Login'
            onClick={(e) => onChange(e)}
          />
          {/* <input
            type='submit'
            name='submit'
            className='btn btn-block btn-primary'
            value='Register'
            onClick={e => onChange(e)}
          /> */}
        </form>
      </div>
    </Fragment>
  );
};

Landing.propTypes = {
  setAlert: PropTypes.func.isRequired,
  generate: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, generate, login })(Landing);
