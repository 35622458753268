import React, {Fragment, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {loadUser} from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import './App.css';

import PrivateRoute from './components/routing/PrivateRoute';
import Navbar from './components/layout/Navbar';
import Auth from './components/auth/Auth';
import Alert from './components/layout/Alert';
import Landing from './components/Landing';
import Logout from './components/auth/Logout';

// Redux
import {Provider} from 'react-redux';
import store from './store';

if (localStorage.token) {
  setAuthToken(localStorage.token);
  store.dispatch(loadUser());
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Navbar />
          <section className='container mt-4'>
            <Alert />
            <Route exact path='/' component={Landing} />
            <Route exact path='/auth/:id' component={Auth} />
            <Switch>
              <PrivateRoute exact path='/logout' component={Logout} />
            </Switch>
          </section>
          {/* <Footer /> */}
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
