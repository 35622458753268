import axios from 'axios';
import {setAlert} from './alert';
import {
  GENERATE_LINK_SUCCESS,
  GENERATE_LINK_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from './types';
import setAuthToken from '../utils/setAuthToken';

const setHeader = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
  };
};

// Load User
export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get('/auth/me');
    dispatch({type: USER_LOADED, payload: res.data.data});
  } catch (error) {
    dispatch({type: AUTH_ERROR});
  }
};

//generate link to login
export const generate = ({slug, email, passwordHash}) => async dispatch => {
  try {
    const reqConfig = setHeader();
    const body = JSON.stringify({slug, email, passwordHash});
    const res = await axios.post('/auth/register', body, reqConfig);
    dispatch({
      type: GENERATE_LINK_SUCCESS,
      payload: res.data.data,
    });
    dispatch(loadUser());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      console.log(errors);
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: GENERATE_LINK_FAIL,
    });
  }
};

//login user
export const login = ({slug, email, passwordHash}) => async dispatch => {
  try {
    const reqConfig = setHeader();
    const body = JSON.stringify({email, passwordHash});
    const res = await axios.post('/auth/' + slug, body, reqConfig);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data.data,
    });
    dispatch(loadUser());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const direct_login = id => async dispatch => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post('/auth/direct/' + id, null, reqConfig);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data.data,
    });
    dispatch(loadUser());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      console.log(errors);
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// LOGOUT
export const logout = user => async dispatch => {
  try {
    const reqConfig = setHeader();
    await axios.post('/auth/cleartoken/' + user._id, null, reqConfig);
    dispatch({
      type: LOGOUT,
    });
    setAuthToken();
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      console.log(errors);
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};
