const rules = {
  user: {
    static: ['dashboard:visit', 'profile:visit', 'profile:update'],
  },
  admin: {
    static: [
      'project:list',
      'project:create',
      'project:edit',
      'project:delete',
      'project:visit',
      'projectCategory:list',
      'projectCategory:create',
      'projectCategory:edit',
      'projectCategory:delete',
      'projectCategory:visit',
      'team:list',
      'team:create',
      'team:edit',
      'team:delete',
      'team:visit',
      'office:list',
      'office:create',
      'office:edit',
      'office:delete',
      'office:visit',
      'user:list',
      'user:create',
      'user:edit',
      'user:delete',
      'user:visit',
      'dashboard:visit',
      'profile:visit',
      'profile:update',
    ],
  },
};

export default rules;
